<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="采购单ID" prop="caiGouDanID" >
                <el-input v-model="form.caiGouDanID" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="商品名称" prop="shangPinMC" >
                <el-input v-model="form.shangPinMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="商品ID" prop="shangPinID" >
                <el-input v-model="form.shangPinID" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="规格" prop="guiGe" >
                <el-input v-model="form.guiGe" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="货号" prop="huoHao" >
                <el-input v-model="form.huoHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="条码" prop="tiaoMa" >
                <el-input v-model="form.tiaoMa" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="分类" prop="fenLei" >
                <el-input v-model="form.fenLei" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="单位" prop="danWei" >
                <el-input v-model="form.danWei" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="生产日期" prop="shengChanRQ">
                <el-date-picker v-model="form.shengChanRQ" size="small" type="date" value-format="yyyy-MM-dd"/>
            </el-form-item>
            <el-form-item label="保质期（天）" prop="baoZhiQiTian" >
                <el-input v-model="form.baoZhiQiTian" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="采购数量" prop="caiGouSL" >
                <el-input v-model="form.caiGouSL" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="已入库数量" prop="ruKuSL" >
                <el-input v-model="form.yiRuKuSL" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="进货价合计" prop="jinHuoJiaHeJi" >
                <el-input v-model="form.jinHuoJiaHeJi" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="进货价" prop="jinHuoJia" >
                <el-input v-model="form.jinHuoJia" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="规格基数" prop="guiGeJiShu" >
                <el-input v-model="form.guiGeJiShu" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="店内零售价" prop="dianNeiLSJ" >
                <el-input v-model="form.dianNeiLSJ" size="small" maxlength="8" show-word-limit/>
            </el-form-item>
            <el-form-item label="备注" prop="beiZhu" >
                <el-input v-model="form.beiZhu" size="small" maxlength="64" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
/*    import {add, update, getUpdate} from "@/service/cggl/CaiGouDanXiangQing";*/
    import {getUpdate} from "@/service/cggl/CaiGouDanXiangQing";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                caiGouDanID: [], // 采购单ID
                shangPinMC: [], // 商品名称
                shangPinID: [], // 商品ID
                guiGe: [], // 规格
                huoHao: [], // 货号
                tiaoMa: [], // 条码
                fenLei: [], // 分类
                danWei: [], // 单位
                shengChanRQ: [], // 生产日期
                baoZhiQiTian: [], // 保质期（天）
                caiGouSL: [], // 采购数量
                yiRuKuSL: [], // 入库数量
                jinHuoJiaHeJi: [], // 进货价合计
                jinHuoJia: [], // 进货价
                guiGeJiShu: [], // 规格基数
                dianNeiLSJ: [], // 店内零售价
                beiZhu: [], // 备注
            }
            this.titlePrefix = '采购单详情';
            this.defaultForm = {
                id: null,
                caiGouDanID: "", // 采购单ID
                shangPinMC: "", // 商品名称
                shangPinID: "", // 商品ID
                guiGe: "", // 规格
                huoHao: "", // 货号
                tiaoMa: "", // 条码
                fenLei: "", // 分类
                danWei: "", // 单位
                shengChanRQ: "", // 生产日期
                baoZhiQiTian: "", // 保质期（天）
                caiGouSL: "", // 采购数量
                yiRuKuSL: "", // 入库数量
                jinHuoJiaHeJi: "", // 进货价合计
                jinHuoJia: "", // 进货价
                guiGeJiShu: "", // 规格基数
                dianNeiLSJ: "", // 店内零售价
                beiZhu: "", // 备注
            };
            this.addService = add;
            this.updateService = update;
           // this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>