<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="caiGouDanID" label="采购单ID" />
            <x-detail-item prop="shangPinMC" label="商品名称" />
            <x-detail-item prop="shangPinID" label="商品ID" />
            <x-detail-item prop="guiGe" label="规格" />
            <x-detail-item prop="huoHao" label="货号" />
            <x-detail-item prop="tiaoMa" label="条码" />
            <x-detail-item prop="fenLei" label="分类" />
            <x-detail-item prop="danWei" label="单位" />
            <x-detail-item prop="shengChanRQ" label="生产日期" />
            <x-detail-item prop="baoZhiQiTian" label="保质期（天）" />
            <x-detail-item prop="caiGouSL" label="采购数量" />
            <x-detail-item prop="ruKuSL" label="入库数量" />
            <x-detail-item prop="jinHuoJiaHeJi" label="进货价合计" />
            <x-detail-item prop="jinHuoJia" label="进货价" />
            <x-detail-item prop="dianNeiLSJ" label="店内零售价" />
            <x-detail-item prop="beiZhu" label="备注" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/cggl/CaiGouDanXiangQing";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                caiGouDanID: "", // 采购单ID
                shangPinMC: "", // 商品名称
                shangPinID: "", // 商品ID
                guiGe: "", // 规格
                huoHao: "", // 货号
                tiaoMa: "", // 条码
                fenLei: "", // 分类
                danWei: "", // 单位
                shengChanRQ: "", // 生产日期
                baoZhiQiTian: "", // 保质期（天）
                caiGouSL: "", // 采购数量
                ruKuSL: "", // 入库数量
                jinHuoJiaHeJi: "", // 进货价合计
                jinHuoJia: "", // 进货价
                guiGeJiShu: "", // 规格基数
                dianNeiLSJ: "", // 店内零售价
                beiZhu: "", // 备注
            };
           /* this.getDetailService = getDetail;*/
        }
    }
</script>

<style scoped>

</style>