<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="采购单ID" >
                <el-input v-model="search.caiGouDanID" size="small" />
            </x-search-item>
            <x-search-item label="商品名称" >
                <el-input v-model="search.shangPinMC" size="small" />
            </x-search-item>
            <x-search-item label="条码" >
                <el-input v-model="search.tiaoMa" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
            <el-table-column label="序号" type="index" fixed/>
            <el-table-column  prop="shangPinMC" label="商品名称" show-tooltip-when-overflow/>
            <el-table-column  prop="guiGe" label="规格" />
            <el-table-column  prop="huoHao" label="货号" show-tooltip-when-overflow/>
            <el-table-column  prop="tiaoMa" label="条码" show-tooltip-when-overflow/>
            <el-table-column  prop="fenLei" label="分类" />
            <el-table-column  prop="danWei" label="单位" />
            <el-table-column  prop="shengChanRQ" label="生产日期" show-tooltip-when-overflow/>
            <el-table-column  prop="baoZhiQiTian" label="保质期（天）" />
            <el-table-column  prop="caiGouSL" label="采购数量" />
            <el-table-column  prop="yiRuKuSL" label="已入库数量" />
            <el-table-column  prop="yiTuiHuoSL" label="已退货数量" />
            <el-table-column  prop="jinHuoJiaHeJi" label="进货价合计" />
            <el-table-column  prop="jinHuoJia" label="进货价" />
            <el-table-column  prop="beiZhu" label="备注" />
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/cggl/CaiGouDanXiangQing";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/cggl/cgspmx/CaiGouDanXiangQingEdit";
    import Detail from "@/view/cggl/cgspmx/CaiGouDanXiangQingDetail";

    export default {
        name: "CaiGouDanXiangQingList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    caiGouDanID: '',
                    shangPinMC: '',
                    tiaoMa: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>
